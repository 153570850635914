<template>
  <b-modal
    size="md"
    id="influencer-create"
    title="Add New Influencer"
    ok-title="Save"
    @ok="submit"
    @hidden="resetForm"
    :ok-disabled="isProcessing"
  >
    <div class="form-group row">
      <div class="col-sm-3">
        <label for="name"
          >Influencer Name <span class="text-danger">*</span></label
        >
      </div>
      <div class="col-sm-9">
        <b-form-input v-model="form.name" id="name"></b-form-input>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-3">
        <label for="keywords">Keywords</label>
      </div>
      <div class="col-sm-9">
        <b-form-tags input-id="keywords" v-model="form.keywords"></b-form-tags>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { MediaInfluencerService } from "@/core/services"
import { required } from "vuelidate/lib/validators"

export default {
  name: "MediaInfluencerCreate",

  data() {
    return {
      form: {
        name: null,
        keywords: [],
      },
      isProcessing: false,
    }
  },
  validations: {
    form: {
      name: { required },
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: null,
        keywords: [],
      }
    },
    async submit(e) {
      e.preventDefault()
      this.isProcessing = true
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.isProcessing = false
        return
      }

      let response = await MediaInfluencerService.create(this.form)
      if (!response.error) {
        this.$emit("success")
        this.$root.$bvToast.toast("Create influencer success", {
          title: `Success`,
          variant: "success",
          solid: true,
        })
        this.$bvModal.hide("influencer-create")
      }
      this.isProcessing = false
    },
  },
}
</script>

<style></style>
