<template>
  <div>
    <div class="row mt-5">
      <div class="col-12">
        <KTCard>
          <template v-slot:title>
            <h6 class="mb-0">Media Influencers</h6>
          </template>
          <template v-slot:toolbar>
            <button-add v-b-modal.influencer-create></button-add>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col sm="2">
                <b-form-select
                  size="sm"
                  v-model="paging.perPage"
                  :options="[10, 25, 50, 100]"
                >
                </b-form-select>
              </b-col>
              <b-col sm="3" offset="7">
                <b-form-input
                  size="sm"
                  type="text"
                  placeholder="Search"
                  v-model="search"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-table
              bordered
              responsive
              :items="items"
              :fields="fields"
              show-empty
              :busy="!isLoaded"
            >
              <template v-slot:table-busy>
                <div class="text-danger my-2">
                  <center>
                    <b-spinner class="align-middle mx-2"></b-spinner>
                    <strong>Loading...</strong>
                  </center>
                </div>
              </template>

              <template v-slot:head(created_at)="data">
                <div style="width:120px">{{ data.label }}</div>
              </template>

              <template v-slot:head(updated_at)="data">
                <div style="width:120px">{{ data.label }}</div>
              </template>
              <template v-slot:empty>
                <center>No data</center>
              </template>

              <template v-slot:cell(no)="row">
                {{ iteration + row.index + 1 }}
              </template>

              <template v-slot:cell(keywords)="row">
                {{ row.item.keywords.join(", ") }}
              </template>

              <template v-slot:cell(actions)="row">
                <div style="width:150px">
                  <button-edit
                    @click="selectedInfluencer = row.item"
                    v-b-modal.influencer-edit
                  >
                    <i class="flaticon2-edit icon-md"></i>
                  </button-edit>
                  <button-delete
                    v-b-modal.delete-influencer
                    @click="selectedInfluencer = row.item"
                  >
                  </button-delete>
                </div>
              </template>
            </b-table>
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <span v-if="paging"
                >{{ paging.totalRows | number_format }} records</span
              >
              <b-pagination
                v-if="paging.numberOfPages"
                v-model="paging.currentPage"
                :total-rows="paging.totalRows"
                align="right"
                :number-of-pages="paging.numberOfPages"
                :per-page="paging.perPage"
                use-router
                @change="changePage"
              ></b-pagination>
            </div>
          </template>
        </KTCard>
      </div>
    </div>
    <MediaInfluencerCreate @success="changePage(1)"></MediaInfluencerCreate>
    <MediaInfluencerEdit
      :influencer="selectedInfluencer"
      v-if="selectedInfluencer"
      @hidden="selectedInfluencer = null"
      @success="getMediaInfluencers"
    ></MediaInfluencerEdit>

    <confirm-delete
      v-if="selectedInfluencer"
      modal-id="delete-influencer"
      :remove-fn="removeInfluencer"
      @ok="getMediaInfluencers"
    ></confirm-delete>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { MediaInfluencerService } from "@/core/services"
import KTCard from "@/view/content/Card.vue"
import MediaInfluencerCreate from "./MediaInfluencerCreate"
import MediaInfluencerEdit from "./MediaInfluencerEdit"
import _ from "lodash"
export default {
  components: {
    KTCard,
    MediaInfluencerCreate,
    MediaInfluencerEdit,
  },
  data() {
    return {
      isLoaded: false,
      selectedInfluencer: null,
      fields: [
        {
          key: "no",
          label: "#",
          class: "text-center",
        },
        {
          key: "name",
          label: "Media Influencer Name",
        },
        {
          key: "keywords",
          label: "Keywords",
        },
        {
          key: "actions",
          label: "Action",
          class: "text-center",
        },
      ],
      items: [],
      search: null,
      paging: {
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        numberOfPages: 0,
      },
    }
  },
  async created() {
    this.isLoaded = false
    await this.getMediaInfluencers()
    this.isLoaded = true
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Media Influencers " }])
  },
  computed: {
    iteration() {
      return this.paging.perPage * (this.paging.currentPage - 1)
    },
  },
  watch: {
    search: _.debounce(function() {
      this.paging.currentPage = 1
      this.getMediaInfluencers()
    }, 1000),
  },
  methods: {
    changePage(page) {
      this.paging.currentPage = page
      this.getMediaInfluencers()
    },
    async getMediaInfluencers() {
      let response = await MediaInfluencerService.get({
        offset: this.paging.perPage * (this.paging.currentPage - 1),
        limit: this.paging.perPage,
        q: this.search,
      })

      this.paging.totalRows = response.total
      this.paging.numberOfPages = Math.ceil(
        this.paging.totalRows / this.paging.perPage
      )
      this.items = response.results || []
    },
    removeInfluencer() {
      return MediaInfluencerService.delete({
        mediaId: this.selectedInfluencer.mediaId,
      })
    },
  },
}
</script>

<style></style>
